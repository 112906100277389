import {Component, OnInit, ViewChild} from '@angular/core';
import {SearchService} from 'src/app/core/services/search.service';
import {distinctUntilChanged, debounceTime} from 'rxjs/operators';
import {BehaviorSubject} from 'rxjs';
import {RoutingHelper} from '../../core/util/routing.helper';
import {BrowserService} from "../../core/services/browser.service";
import {IFacetCount, ISearchResult} from "../../core/model/searchResult.model";
import {TranslateService} from "@ngx-translate/core";

@Component({
    selector: 'app-search',
    templateUrl: './search.component.html'

})
export class SearchComponent implements OnInit {
    searchIsExpanded = false;
    displayResults = false;
    searchResults: ISearchResult;
    loading = true;
    searchUpdated = new BehaviorSubject('');
    showMinLength = false;


    @ViewChild('search') searchBar: any;

    constructor(
        private searchService: SearchService,
        public routingHelper: RoutingHelper,
        public browserService: BrowserService,
        private trans: TranslateService,
    ) {
        this.searchUpdated.asObservable()
            .pipe(debounceTime(300))
            .pipe(distinctUntilChanged()).subscribe((query) => this.sendRequest(query));
    }

    ngOnInit() {

    }

    sendRequest(value) {
        if (value.length > 2 || value === 'ec') {
            this.loading = true;
            this.showMinLength = false;
            this.displayResults = true;
            this.searchUpdated.next(value);

            this.searchService.runSearch(value).then(result => {
                this.loading = false;
                this.displayResults = true;
                this.searchResults = result;
            })
        } else if (value && value.length < 3) {
            this.showMinLength = true;
            this.searchResults = null;
            this.displayResults = true;
            this.loading = false;
        } else {
            // zero characters
            this.showMinLength = false;
            this.searchResults = null;
            this.displayResults = false;
        }
    };

    onClickOutside() {
        setTimeout(() => {
            this.searchIsExpanded = false;
            this.displayResults = false;
        }, 300)
    }

    expandSearch(): void {
      this.searchIsExpanded = true;
      setTimeout(() => this.searchBar.setFocus(), 0)
    }

    getContentHeadlineFromFacets(facetCounts: IFacetCount[]) {
        const parent = facetCounts.find((facet) => {
            return facet.field_name === 'parent_type_facet';
        })

        if (!parent) {
            return this.trans.instant('search_type.content')
        }

        const parentTypeCount = parent.counts.map((count) => {
            return count.count
        });

        if (parent.counts.length > 1) {
            return this.trans.instant('search_headline.multiple', {
                count: parentTypeCount,
                list: parent.counts.slice(0, parent.counts.length - 2).map((count) => {
                    return this.trans.instant('search_type.' + count.value)
                }).join(', '),
                last: this.trans.instant('search_type.' + parent.counts[parent.counts.length - 1].value)
            })
        }

        return this.trans.instant('search_headline.single', {
            count: parentTypeCount,
            item: this.trans.instant('search_type.' + parent.counts[0].value)
        });
    }

    getCleanSnippet(snippet: string) {
        if (snippet) {
            return snippet.split('\n').find((subSnippet) => {
                return subSnippet.indexOf('<strong>') > -1;
            })?.trim();
        }

        return '';
    }
}
