<div class="d-flex align-items-start {{ noBlock ? '' : 'block--' + align }}" [class.no-block]="noBlock" [class.text-container]="icon" [class.text-block]="!icon">
  <div *ngIf="icon" class="icon-col text-primary">
    <app-icon [icon]="icon"></app-icon>
  </div>
  <div class="icon-box-content" [class.icon-box-content--with-icon]="icon">
    <h2 *ngIf="headline" id="{{idSlug}}">{{headline}}</h2>
    <!-- ng-content will put all the children -->
    <ng-content></ng-content>
  </div>
</div>
