import {NewsFeedComponent} from './news-feed.component';
import {IWidgetDefinition} from '../../core/model/widget.model';

export default ({
    fields: [
        {
            id: 'filterConfig',
            label: 'Filter Config',
            input: 'text',
            required: true,
        },
    ]
} as IWidgetDefinition);
