import {IWidgetDefinition} from '../../core/model/widget.model';
import currencySelect from "../../core/util/forms/currency.select";

const isPopupFunction = (formValues) => {
    return !formValues.link_to_page;
};

export default ({
    icon: {style: 'fa-solid', name: 'wallet'},
    fields: [
        {
            id: 'headline',
            input: 'text',
            default: 'Contribute to the Project'
        },
        {
            id: 'text',
            label: 'Text',
            input: 'html',
            default: 'If you feel a connection to our project and would like to support this activity, you can find more information below.'
        },
        currencySelect,
        {
            id: 'donation_target',
            input: 'number',
            description: 'Allows to set a donation target'
        },
        {
            id: 'donation_current',
            input: 'number',
            description: 'The current donated amount'
        },
        {
            id: 'link_to_page',
            input: 'boolean',
        },
        {
            id: 'link',
            input: 'route',
            description: 'Full relative link to a DW-Connect page.',
            required: true,
            depends: (formValues) => {
                return formValues.link_to_page;
            }
        },
        {
            id: 'work_with_us_preview',
            input: 'html',
            depends: isPopupFunction
        },
        {
            id: 'work_with_us_overlay',
            input: 'html',
            depends: isPopupFunction
        },
        {
            id: 'buttons',
            label: 'Donation Buttons',
            input: 'complex-list',
            depends: isPopupFunction,
            subtype: [
                {
                    id: 'label',
                    input: 'text',
                    default: 'How to support?',
                },
                {
                    id: 'link',
                    input: 'text',
                    description: 'If empty, the overlay will be opened. (unless a dedicated donation page is configured)'
                }
            ]
        }

    ]
} as IWidgetDefinition)
