<ng-container *ngIf="!asFavorite">
  <div class="text-end">

    <button *ngIf="invited"
            class="btn btn-primary"
            type="button"
            (click)="accept()"
            title="Your are a invited to join {{entityType}} {{entityName}}. Click to accept the invitation."
    >
      <span *ngIf="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      Join {{entityType}}
    </button>
    <button *ngIf="invited"
            class="btn btn-outline-primary"
            type="button"
            (click)="ignore()"
            title="Your are a invited to join {{entityType}} {{entityName}}. Click to accept the invitation."
    >
      <span *ngIf="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      Ignore Invitation
    </button>

    <button *ngIf="member && !leaving"
            class="btn btn-outline-dark"
            type="button"
            (click)="leave()"
            title="Your are a member of {{entityName}}. Click to leave this {{entityType}}."
    >
      <span *ngIf="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      Leave {{entityType}}
    </button>

    <ng-container *ngIf="member && leaving">
      <div class="mb-2">Leave {{entityType}}?</div>

      <button class="btn btn-outline-primary"
              type="button"
              (click)="leaveConfirm()"
              title="Click to leave the {{entityType}}"
      >
        <span *ngIf="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        OK
      </button>

      <button class="btn btn-outline-primary"
              type="button"
              (click)="leaveCancel()"
              title="Click to cancel"
      >
        <span *ngIf="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        Cancel
      </button>

    </ng-container>

    <button *ngIf="!invited && !member && !following"
            type="button"
            class="btn btn-outline-primary"
            (click)="follow()"
            title="Click to follow {{entityType}} {{entityName}}"
    >
      <span *ngIf="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      Follow
    </button>

    <button *ngIf="!invited && !member && following"
            type="button"
            class="btn btn-outline-dark"
            (click)="unfollow()"
            title="Your are following {{entityType}} {{entityName}}. Click to unfollow this {{entityType}}."
    >
      <span *ngIf="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      Unfollow
    </button>

    <div *ngIf="editable">
      <button class="btn btn-link btn-link--secondary btn-sm fs-7" type="button" (click)="showFollowersModal()">
        {{ followerCount == 0 ? '0 followers' : 'See all ' + followerCount + ' followers'  }}
      </button>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="asFavorite">

  <div class="btn-group" role="group" aria-label="Mark as favorite">
    <button (click)="main()" type="button" class="btn btn-outline-primary">
      <span *ngIf="loading" class="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
      <i class="fa-{{following ? 'solid' : 'regular'}} fa-star"></i>
    </button>
    <button type="button" class="btn btn-outline-primary" [ngbTooltip]="following ? 'Click to remove this as your favorite.' : 'Click to mark this as a your favorite. You\'ll be able to find it more easy afterwards.'" triggers="click:blur">
      <i class="fa-solid fa-question"></i>
    </button>
  </div>
</ng-container>
