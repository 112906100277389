import {Injectable} from '@angular/core';
import gql from 'graphql-tag';
import {Apollo} from 'apollo-angular';
import {MemberPublicInfoFragment} from './GQL/gql-query.service';
import {TypesenseService} from './typesense.service';
import {AnalyticsService} from './analytics.service';
import {combineLatest, firstValueFrom} from 'rxjs';
import {ISearchResult} from '../model/searchResult.model';
import {cloneDeep} from "lodash-es";


@Injectable({
    providedIn: 'root'
})
export class SearchService {

    constructor(
        private apollo: Apollo,
        private typesenseService: TypesenseService,
        private tracker: AnalyticsService
    ) {
    }

    async runSearch(input: string): Promise<ISearchResult> {


        const searchResult = await firstValueFrom(combineLatest(
            [
                this.runBackendSearch(input),
                this.typesenseService.search(input)
            ]
        ));

        const res = cloneDeep(searchResult[0]);

        let count = 0;
        Object.keys(res).forEach((key) => {
            count += res[key].preview?.length || 0;
        });

        console.log(searchResult[1].hits);

        res.content = searchResult[1];
        count += res.content.hits.length;

        res.total = count;


        this.tracker.trackSearch(input, count);

        return res;
    }

    async runBackendSearch(query: string, limit = 8) {
        const backendSearchQuery = gql`{
        search (query: "${query}", limit: ${limit}) {
          spaces {
            totalCount
            preview {
              href
              title
              image {
                src
                aspectRatio
                focal
              }
            }
          }
          centers {
            totalCount
            preview {
              id
              displayName
              slug
              name
              pictures {
                url
              }
              country {
                code
              }
            }
          }
          countries {
            totalCount
            preview {
              id
              name
              code
            }
          }
          members {
            totalCount
            preview {
              ...MemberPublicInfoFragment
            }
          }
        }
      }
      ${MemberPublicInfoFragment}
      `;

        const res = await this.apollo.use('app')
            .query({
                query: backendSearchQuery
            }).toPromise();

        return (res.data as any).search as ISearchResult;
    }

    async runCenterSearch(query, limit = 15, includeAddress = false) {
        const result: any = await this.apollo
            .use('app')
            .query({
                query: gql`{
                  search (query: "${query}", limit: ${limit || 15}) {
                    centers {
                      totalCount
                      preview{
                        id
                        displayName
                        type
                        slug
                        name
                        pictures {
                          url
                        }
                        country {
                          id
                          code
                        }
                        latitude
                        longitude
                        ${includeAddress ? `mainAddress {
                          street_address
                          city
                          postal_code
                          region
                          country
                        }` : ''}
                        }
                      }
                    }
                  }`
            }).toPromise();
        return result?.data.search?.centers;
    }


    async runMemberSearch(input) {
        const result: any = await this.apollo
            .use('app')
            .query({
                query: gql`{
            search (query: "${input}", limit: 8) {
              members {
                totalCount
                preview {
                  ...MemberPublicInfoFragment
                }
              }
            }
          }
          ${MemberPublicInfoFragment}
          `
            }).toPromise();

        return result?.data.search?.members;
    }

    async runFollowerSearch(input: string, followedEntityId: string, followedEntityType: string, limit = 8) {
        const result: any = await this.apollo
            .use('app')
            .query({
                query: gql`{
            search (query: "${input}", limit: ${limit}, followedEntityId: "${followedEntityId}", followedEntityType: "${followedEntityType}") {
              followers {
                totalCount
                preview {
                  ...MemberPublicInfoFragment
                }
              }
            }
          }
          ${MemberPublicInfoFragment}
          `
            }).toPromise();

        return result?.data.search?.followers;
    }
}
