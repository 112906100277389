<div class="text-gray"
     [ngClass]="{'activeContainer':searchIsExpanded}">
    <svg xmlns="http://www.w3.org/2000/svg" class="w-2 h-2 cursor-pointer" viewBox="0 0 20 20" fill="currentColor"
         (click)="expandSearch()">
        <path fill-rule="evenodd"
              d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
              clip-rule="evenodd"/>
    </svg>
    <input #search class="form-control" [ngClass]="{'d-none': !searchIsExpanded}" placeholder="Search..."
               (input)="searchUpdated.next(search.value.toString().trim().toLocaleLowerCase())"/>
    <div *ngIf="displayResults">
        <div class="dropdown" [ngClass]="{'active': searchIsExpanded}" #searchResultsDropdown>


          <div *ngIf="loading" class="spinner-border text-primary" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>

            <div *ngIf="!loading && showMinLength">
                <h5 class="noResults">Please type at least 3 characters</h5>
            </div>

            <div *ngIf="!loading && !showMinLength && !searchResults?.total">
                <h5 class="noResults">No Results</h5>
            </div>

            <div *ngIf="!loading" class="search-results">
                <div *ngIf="searchResults?.content.found > 0" class="resultSection">
                    <strong>{{getContentHeadlineFromFacets(searchResults.content.facet_counts)}}:</strong>
                    <ul>
                        <li *ngFor="let hit of searchResults.content.hits">
                            <a [routerLink]="hit.document.route">
                                <div class="nav-link" [innerHTML]="hit.highlight.title?.snippet || hit.document.title">

                                </div>
                                <div *ngIf="hit.highlight.content" class="resultSection__preview"
                                     [innerHTML]="getCleanSnippet(hit.highlight.content.snippet)">
                                </div>
                            </a>
                        </li>
                    </ul>
                </div>
                <div *ngIf="searchResults?.spaces.preview.length > 0" class="resultSection">
                    <strong>Spaces:</strong>
                    <ul>
                        <li *ngFor="let space of searchResults.spaces.preview">
                            <a href="{{space.href}}" (click)="browserService.navigateLink(space.href)" class="nav-link">
                                <div class="nav-link">
                                    {{ space.title }}
                                </div>
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="resultSection" *ngIf="searchResults?.centers.preview.length > 0">
                    <strong>Centers:</strong>
                    <ul>
                        <li *ngFor="let center of searchResults.centers.preview">
                            <a [routerLink]="routingHelper.getCenterRoute(center)" class="nav-link">
                                <div class="nav-link">
                                    {{ center.name }}
                                </div>
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="resultSection" *ngIf="searchResults?.countries.preview.length > 0">
                    <strong>Countries:</strong>
                    <ul>
                        <li *ngFor="let country of searchResults.countries.preview">
                            <a [routerLink]="routingHelper.getCountryRouteByCode(country.code)" class="nav-link">
                                <div class="nav-link">
                                    {{ country.name }}
                                </div>
                            </a>
                        </li>
                    </ul>
                </div>

                <div class="resultSection" *ngIf="searchResults?.members.preview.length > 0">
                    <strong>People:</strong>
                    <ul>
                        <li *ngFor="let member of searchResults.members.preview">
                            <a [routerLink]="routingHelper.getMemberRoute(member)">
                                <div class="nav-link">
                                    {{ member.givenName + " " + member.familyName }}
                                </div>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

    </div>
</div>
