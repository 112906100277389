import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MenuBarComponent } from '../components/menu-bar/menu-bar.component';
import { ImgComponent } from '../components/img/img.component';
import { MenuComponent } from '../widgets/menu/menu.component';
import { TranslateModule } from '@ngx-translate/core';
// import { TopMenuComponent } from '../components/top-menu/top-menu.component';
// import { ChipComponent } from '../components/chip/chip.component';
// import {CardComponent} from '../components/card/card.component';
import { SearchComponent } from '../components/search/search.component';
// import { IntranetNavigationComponent } from '../components/intranet-navigation/intranet-navigation.component';
// import {SearchModal} from '../components/search-modal/search-modal.component';
// import {ClickOutsideModule } from 'ng-click-outside';
import { FooterComponent } from '../components/footer/footer.component';

import { RouterModule } from '@angular/router';
import {
  NgbDropdownModule,
  NgbModule,
  NgbTooltipModule,
} from '@ng-bootstrap/ng-bootstrap';
import { NotFoundTmplComponent } from '../templates/not-found/not-found-tmpl.component';
import { BareTemplateComponent } from '../templates/bare/bare-template.component';
import { TemplateSectionComponent } from '../components/template-section/template-section.component';
import { GenericComponent } from '../widgets/generic.component';
import { DwcWidgetDirective } from '../core/directives/dwc-widget.directive';
import { ProminentLinkComponent } from '../widgets/prominent-link/prominent-link.component';
import { ImageComponent } from '../widgets/image/image.component';
import { CenterTemplateComponent } from '../templates/center/center-template.component';
import { PageLoaderComponent } from '../components/page-loader/page-loader.component';
import { FollowButtonComponent } from '../components/follow-button/follow-button.component';
import { ModalFrameComponent } from '../components/modal-frame/modal-frame.component';
import { PeopleListModalComponent } from '../components/people-list-modal/people-list-modal.component';
import { MemberPreviewComponent } from '../components/member-preview/member-preview.component';
import { EditButtonComponent } from '../components/edit-button/edit-button.component';
import { UserAlertComponent } from '../components/user-alert/user-alert.component';
import { SectionHeaderComponent } from '../components/section-header/section-header.component';
import { WelcomeOverlayComponent } from '../components/welcome-overlay/welcome-overlay.component';
import { VisibilitySelectComponent } from '../components/visibility-select/visibility-select.component';
import { OneColTemplateComponent } from '../templates/one-col/one-col-template.component';
import { TwoColTemplateComponent } from '../templates/two-col/two-col-template.component';
import { ProjectsListComponent } from '../widgets/projects-list/projects-list.component';
import { ConfirmationPopoverComponent } from '../components/confirmation-popover/confirmation-popover.component';
import { TextComponent } from '../widgets/text/text.component';
import { IconBoxComponent } from '../components/icon-box/icon-box.component';
import { IconComponent } from '../components/icon/icon.component';
import { WidgetGridComponent } from '../widgets/widget-grid/widget-grid.component';
import { PeopleListComponent } from '../components/people-list/people-list.component';
import { MapComponent } from '../components/map/map.component';
import { MessagesComponent } from '../components/messages/messages.component';
import { TileComponent } from '../components/tile/tile.component';
import { VideoComponent } from '../components/video/video.component';
import { VideoWidgetComponent } from '../widgets/video/video.component';
import { WidgetFollowButtonComponent } from '../widgets/follow-button/follow-button.component';
import { HighlightedStringComponent } from '../components/highlighted-string/highlighted-string.component';
import { BannerPageTemplateComponent } from '../templates/banner-page/banner-page-template.component';
// import {UpdatesSectionComponent} from '../components/updates-section/updates-section.component';
import {InspirationComponent} from '../widgets/inspiration/inspiration.component';
import {SwiperDirective} from "../core/directives/swiper.directive";
import {ChildPagesComponent} from "../widgets/child-pages/child-pages.component";
import {QuoteComponent} from "../widgets/quote/quote.component";
import {CenterTileComponent} from "../components/center-tile/center-tile.component";
import {ProjectTileComponent} from "../components/project-tile/project-tile.component";
import {ColSizeClassesPipe} from "../core/pipes/col-size-classes.pipe";
// import {IconSvgComponent} from '../components/icon-svg/icon-svg.component'; // it needs to be part of teh core, as we are including it in all kind of places through the posts

/**
 * This module is a helper to make core framework components available to the app
 */
@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    NgbModule,
    NgbTooltipModule,
    NgbDropdownModule,
    RouterModule.forChild([]),
    // ClickOutsideModule,
  ],
  declarations: [
    DwcWidgetDirective,
    SwiperDirective,
    ColSizeClassesPipe,

    MenuBarComponent,
    ModalFrameComponent,
    PeopleListModalComponent,
    MemberPreviewComponent,
    FollowButtonComponent,
    ImgComponent,
    MenuComponent,
    NotFoundTmplComponent,
    BareTemplateComponent,
    CenterTemplateComponent,
    OneColTemplateComponent,
    TwoColTemplateComponent,
    BannerPageTemplateComponent,
    FooterComponent,
    MessagesComponent,
    PageLoaderComponent,
    TemplateSectionComponent,
    GenericComponent,
    ProminentLinkComponent,
    EditButtonComponent,
    VisibilitySelectComponent,
    ImageComponent,
    ProjectsListComponent,
    ConfirmationPopoverComponent,
    TextComponent,
    IconBoxComponent,
    IconComponent,
    WidgetGridComponent,
    PeopleListComponent,
    MapComponent,
    TileComponent,
    VideoComponent,
    WidgetFollowButtonComponent,
    SectionHeaderComponent,
    HighlightedStringComponent,
    WelcomeOverlayComponent,
    UserAlertComponent,
    VideoWidgetComponent,
    InspirationComponent,
    ChildPagesComponent,
    QuoteComponent,
    CenterTileComponent,
    ProjectTileComponent,
    SearchComponent,
    /*
    IntranetNavigationComponent,
    SearchModal,
    SideMenuComponent,
    TopMenuComponent,
    ChipComponent,
    CardComponent,
    MemberPreviewComponent,
    UpdatesSectionComponent,
    IconSvgComponent

     */
  ],
  exports: [
    SwiperDirective,
    ColSizeClassesPipe,

    MenuBarComponent,
    ModalFrameComponent,
    ImgComponent,
    MenuComponent,
    MemberPreviewComponent,
    FollowButtonComponent,
    FooterComponent,
    MessagesComponent,
    PageLoaderComponent,
    TemplateSectionComponent,
    GenericComponent,
    EditButtonComponent,
    VisibilitySelectComponent,
    ImageComponent,
    ProjectsListComponent,
    TextComponent,
    IconBoxComponent,
    IconComponent,
    WidgetGridComponent,
    PeopleListComponent,
    MapComponent,
    TileComponent,
    VideoComponent,
    WidgetFollowButtonComponent,
    SectionHeaderComponent,
    HighlightedStringComponent,
    UserAlertComponent,
    CenterTileComponent,
    ProjectTileComponent,
    InspirationComponent,
    SearchComponent,
    /*
      SideMenuComponent,
      CoreModule,
      CoreModule,
      TopMenuComponent,
      ChipComponent,
      CardComponent,
      PageLoaderComponent,
      IconBoxComponent,
      IconSvgComponent,
      MemberPreviewComponent,
      UpdatesSectionComponent,

         */
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppComponentsModule {}
