import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {DWCWidgetComponent} from '../../core/model/dwc-widget.model';
import {WidgetService} from '../../core/services/cms/widget.service';
import {IWidget} from '../../core/model/widget.model';
import {getSubColSizes} from '../../core/util/template.helper';
import {IColSizes} from '../../core/model/colSizes.model';
import {StructureService} from '../../core/services/cms/structure.service';
import {TranslationHelper} from '../../core/util/translation.helper';
import {EventService} from '../../core/services/event.service';
import {PostsService} from '../../core/services/posts.service';
import {IntercomService} from '../../core/services/intercom.service';
import {Subject, Subscription} from 'rxjs';
import {UserService} from '../../core/services/user.service';
import {get} from 'lodash-es';
import letterDef from '../lama-letters/lama-letters.def';
import {GqlService} from 'src/app/core/services/GQL/gql.service';
import {transformPostToInspiration} from './utils';
import {RoutingHelper} from '../../core/util/routing.helper';
import {IPost} from '../../core/model/post.model';
import {AccessService, PERMISSION_POST, PERMISSION_UPDATE} from '../../core/services/access.service';
import {MemberService} from '../../core/services/member.service';
import {IUser} from '../../core/model/user.model';
import {PostHelper} from '../../core/util/post.helper';
import {SwiperOptions} from "swiper/types/swiper-options";
import {SwiperContainer} from "swiper/swiper-element";

@Component({
    selector: 'app-widget-grid',
    templateUrl: './widget-grid.component.html',
})
export class WidgetGridComponent implements OnInit, OnDestroy, DWCWidgetComponent {

    // @ViewChild('slidesRef') slides: IonSlides;
    @ViewChild('yearInput') yearInput: ElementRef;
    @ViewChild('typeInput') typeInput: ElementRef;

    @ViewChild('slides', {static: false}) slides: ElementRef<SwiperContainer>;

    colSizes: object;
    data: any;
    user: any;
    id: number;
    type: string;
    preventUnsubscribe = false;

    loading = true;
    lazyLoading = false;
    offset = 0;
    limit = 4;
    allLoaded = false;

    cols = 3;
    innerColSizes: IColSizes;
    displaySizes: IColSizes;

    widgets: any[] = []; // IPost or IWidget

    implicitFilter = null;

    filters = {};
    isFiltered = false;
    filtering = false;

    exporting = false;

    parentId: number;
    parentData: any;
    isManualPosts = false;

    editMode = false;
    editable = false;
    updateAllowed = false;

    childType: string;

    sections: { widgets: IWidget[], widgetRows: number, headline?: string, widgetType?: string }[] = [];

    headline: string;
    description: string;
    allItemsRoute: string;
    linkText: string;

    display: string;
    widgetDisplay: string;

    openedByDefault: boolean;
    definedStates: Map<string, any>;

    $intercom: Subscription;
    $editMode: Subscription;
    $editable: Subscription;

    sliderOptions = {
        slidesPerView: "auto",
        centerInsufficientSlides: true,
        spaceBetween: 8
    } as SwiperOptions;

    disablePrevBtn = true;
    disableNextBtn = false;


    letterTypesFilter: { label: string; value: string }[];

    constructor(
        private widgetService: WidgetService,
        private eventService: EventService,
        private postsService: PostsService,
        public structureService: StructureService,
        private accessService: AccessService,
        public translationHelper: TranslationHelper,
        private translateService: TranslateService,
        private intercomService: IntercomService,
        private userProvider: UserService,
        private gqlService: GqlService,
        private routingHelper: RoutingHelper,
        private memberService: MemberService,
        private postHelper: PostHelper,
    ) {

        this.$editMode = this.structureService.editModeObservable.subscribe(async (editMode) => {
            if (this.editMode !== editMode && !editMode) {
                this.setup(); // reload fresh in case something changed
            }
            this.editMode = editMode;
            this.updateAllowed = await this.structureService.rightCurrentlyGranted(
                ['post', 'letter'].indexOf(this.parentData?.type) < 0 ? PERMISSION_UPDATE : PERMISSION_POST
            );
        });
        this.$editable = this.accessService.editableObservable.subscribe(editable => this.editable = editable);
        this.definedStates = new Map();
        this.openedByDefault = false; // TODO: default state
    }

    /**
     * This function should be always the first, after the constructor -
     * so that it is easy to read what is going on
     */
    ngOnInit() {
        if (!this.data.limit) {
            this.$intercom = this.intercomService.subject.subscribe(message => message === 'scrolled-to-the-end' && this.loadNextWidgetGrid());
        }

        this.setup();
    }

    ngOnDestroy() {
        if (this.$intercom) {
            this.$intercom.unsubscribe();
        }

        this.$editMode?.unsubscribe();
        this.$editable?.unsubscribe();
    }

    reset() {
        this.widgets = [];
        this.offset = 0;
        this.allLoaded = false;
        this.loading = true;
        this.lazyLoading = false;
        this.isFiltered = false;
        this.filtering = false;
    }

    async setup() {
        // reset potentially changed variables
        this.reset();

        // initialize other vars
        this.letterTypesFilter = letterDef.fields.find((fieldDef => fieldDef.id === 'type')).options // @todo: make this line generic
        this.childType = this.data.childType || this.data.type;
        this.headline = this.translationHelper.getBestLanguageValue(this.data.headline);
        this.description = this.translationHelper.getBestLanguageValue(this.data.description);
        this.linkText = this.translationHelper.getBestLanguageValue(this.data.linkText);
        this.display = this.data.widgetGridDisplay || 'grid'; // the default value is important, as we have added this field later on
        this.widgetDisplay = this.data.widgetDisplay || 'preview';

        if (this.data.cols) {
            this.cols = parseInt(this.data.cols, 10);
        }
        this.limit = [1, 4, 8, 15, 24, 35, 48, 63, 80, 99, 120][this.cols];
        if (this.childType === 'letter') {
            this.limit = this.limit * 2;
        }

        this.parentId = this.data.targetId ? Number.parseInt(this.data.targetId, 10) : this.id;

        const colSizes = {xs: 12, sm: 12, md: 12, lg: (12 / this.cols)};

        this.innerColSizes = getSubColSizes(this.colSizes, colSizes);
        this.displaySizes = getSubColSizes({}, colSizes);

        if (this.data.targetId) {
            const target = await this.widgetService.getWidget(this.parentId);

            if (target) {
                this.preventUnsubscribe = target.preventUnsubscribe;

                // @ts-ignore
                const parentPages = target.pages;
                if (parentPages && parentPages.length) {
                    this.allItemsRoute = parentPages[0].route;
                }

                this.parentData = target.data;
                this.childType = this.parentData.type;

            } else {
                console.error('Unknown widget with id %o', this.data.targetId);
            }
        } else {
            this.parentData = this.data;
        }

        try {

            if (this.parentData.implicitFilter) {
                let filter = this.parentData.implicitFilter;

                // try replace of user data
                const regex = /\$user\.([a-z.]+)/gmi;
                this.user = await this.userProvider.fetchLoggedInUser();
                let m;

                // tslint:disable-next-line:no-conditional-assignment
                while ((m = regex.exec(filter)) !== null) {
                    // This is necessary to avoid infinite loops with zero-width matches
                    if (m.index === regex.lastIndex) {
                        regex.lastIndex++;
                    }

                    filter = filter.replace(m[0], get(this.user, m[1], ''))
                }

                this.implicitFilter = JSON.parse(filter);
            } else {
                this.implicitFilter = null;
            }
        } catch (e) {
            console.error('Could not parse %o as json', this.parentData.implicitFilter);
        }

        this.isManualPosts = ['Center', 'Project', 'Page'].indexOf(this.parentData.postsOf) > -1;

        if (['post', 'room'].indexOf(this.childType) > -1 && this.isManualPosts) {
            this.parentId = this.parentData.parentId;
        }

        await this.loadNextWidgetGrid();
    }

    async loadNextWidgetGrid() {

        if (this.lazyLoading || this.allLoaded) {
            return
        }
        this.lazyLoading = true // only needed for the initial load

        let additonalWidgets = [];
        let filter = null;

        switch (this.parentData.type) {
            default:

                filter = Object.keys(this.filters).length ? {
                    andX: Object.keys(this.filters).map((filterKey) => {
                        return {like: {data: `%"${filterKey}":"${this.filters[filterKey]}%`}}
                    })
                } : null;

                if (this.implicitFilter) {
                    if (filter) {
                        filter.andX.push(this.implicitFilter);
                    } else {
                        filter = this.implicitFilter;
                    }
                }

                const data = await this.widgetService.getCollectionWidgetsByType(
                    this.parentId, this.parentData.type, this.offset, parseInt(this.data.limit, 10) || this.limit, filter, 'sort', 'DESC'
                );

                additonalWidgets = data.widget_list?.widgets ? data.widget_list.widgets : [];

                break;

            case 'letter':
            case 'post':

                let posts;

                const mainFilter: any = {
                    andX: [
                        {eq: {parentType: this.isManualPosts ? this.parentData.postsOf : 'widget'}},
                        {eq: {parentId: this.parentId}}
                    ]
                };

                filter = Object.keys(this.filters).length ? {
                    andX: Object.keys(this.filters).map((filterKey) => {
                        return {like: {data: `%"${filterKey}":"${this.filters[filterKey]}%`}}
                    })
                } : null;

                let finalFilter = mainFilter;

                if (this.implicitFilter || filter || this.parentData.limitByCategories) {

                    finalFilter = {
                        andX: [
                            mainFilter,

                            this.parentData.limitByCategories && this.parentData.limitByCategories.length ?
                                {in: {categories: this.parentData.limitByCategories}} : null,

                            this.implicitFilter,
                            filter,
                        ].filter((filterOrNull) => filterOrNull)
                    };
                }

                switch (this.parentData.postsOf) {
                    default:

                        if (this.parentData.type === 'letter') {
                            additonalWidgets = await this.postsService.getWidgetsByFilter(finalFilter, this.offset, this.data.limit || this.limit);
                        } else {
                            console.error('unhandled post source %o', this.parentData.postsOf);
                        }
                        break;
                    case 'Project':
                    case 'Center':
                    case 'Page':
                    case 'widget':
                        posts = await this.postsService.getWidgetsByFilter(finalFilter, this.offset, this.data.limit || this.limit);
                        additonalWidgets = posts.map((post) => this.preparePostForPresentation(post));
                        break;
                    case 'events':
                        const eventParams: any = this.eventService.buildSimpleParams(this.parentData);
                        const events = await this.eventService.getOneTimeEvents(eventParams);

                        if (events.length) {
                            events.forEach((event) => {
                                mainFilter.orX.push({
                                    andX: [
                                        {eq: {parentType: 'event'}},
                                        {eq: {parentId: [event.sourceType, event.sourceId].join('/')}}
                                    ]
                                });
                            });

                            posts = await this.postsService.getWidgetsByFilter(finalFilter, this.offset, this.data.limit || this.limit);
                            additonalWidgets = posts.map((post) => {

                                // get the center
                                const connectedEvent = events.find((event) => {
                                    return [event.sourceType, event.sourceId].join('/') === post.parentId;
                                });

                                // return an IWidget construct
                                const inspiration = transformPostToInspiration(post);

                                if (connectedEvent) {

                                    inspiration.data.slide = {
                                        route: this.routingHelper.getEventDetailRoute(connectedEvent),
                                        location_route: this.routingHelper.getCenterRouteBySlug(connectedEvent.organizingCenter?.slug),
                                        location: connectedEvent.location.name || connectedEvent.location.addressLocality,
                                        sub_location: this.translateService.instant('country.' + connectedEvent.location.addressCountry)
                                    };
                                }

                                return inspiration;
                            });
                        }
                        break;
                }

                break;
        }

        additonalWidgets = additonalWidgets.map((widget) => {

            if (!widget.data) {
                widget.data = {};
            }

            widget.data._onUpdate = new Subject();

            if (this.display === 'slider') {
                widget.data.maxWidth = (this.data.slideWidth || 200) + 'px'
            }

            return widget;
        })

        this.widgets = this.widgets.concat(additonalWidgets);
        this.loading = false;
        this.lazyLoading = false;
        this.offset += this.limit;
        this.buildSections();

        if (this.display === 'accordion') {
            this.updateWidgets()
        }
        if (additonalWidgets.length === (this.data.limit || this.limit)) {
            this.intercomService.sendMessage('resume-firing-scrolled-to-bottom-events')
        } else {
            // we loaded all of them, as the batch was smaller the the limit
            this.allLoaded = true;
        }

        if (this.data.setHiddenOnNoResults && this.structureService.getCurrentPage()) {
            this.structureService.getCurrentPage().hiddenWidgets[this.id.toString()] = this.widgets.length === 0;
        }

    }

    preparePostForPresentation(post) {
        if (this.isManualPosts) {
            post = this.postHelper.prepareForPresentation(post);

            post.type = 'post';
        } else {
            post = transformPostToInspiration(post);
        }

        return post;
    }

    updateWidgets() {
        this.sections.forEach((section, s) => {
            section.widgets.forEach((widget, i) => {

                // this automatically binds the section and the current index to the data.accordionToggle variable
                // s wil be predefined, i as well with the current values
                widget.data.accordionToggle = this.accordionToggle.bind(this, s, i);
                widget.data.display = this.display
                widget.data.isOpen = !this.isClosed(s, i)
            })
        });
    }

    isClosed(s, i) {
        return !(this.definedStates.get(s + '_' + i) && this.definedStates.get(s + '_' + i).state ||
            !this.definedStates.get(s + '_)' + i) && this.openedByDefault)
    }

    accordionToggle(s, i) {
        const key = s + '_' + i;

        if (this.definedStates.has(key)) {
            this.definedStates.set(key, {state: !this.definedStates.get(key).state})
        } else {
            this.definedStates.set(key, {state: !this.openedByDefault})
        }
        this.definedStates.forEach((value, k) => {
            if (k !== key) {
                this.definedStates.set(k, false)
            }
        })

        this.updateWidgets()
    }

    buildSections() {
        if (this.data.categories && this.data.categories.length) {

            const catSections = {};

            const defaultSection = {
                headline: 'Others',
                widgetRows: 0,
                widgets: []
            };

            this.widgets.forEach((widget) => {
                const cat = widget.category;

                if (cat) {
                    if (!catSections[cat]) {
                        catSections[cat] = {
                            headline: '',
                            widgetRows: 0,
                            widgets: []
                        }
                    }

                    catSections[cat].widgets.push(widget);
                } else {
                    defaultSection.widgets.push(widget);
                }
            });

            this.sections = [];

            this.data.categories.forEach((cat) => {
                const catId = cat.id;

                if (catSections[catId]) {
                    catSections[catId].headline = this.translationHelper.getBestLanguageValue(cat.label);
                    catSections[catId].widgetRows = Math.ceil(catSections[catId].widgets.length / this.cols);
                    this.sections.push(catSections[catId])
                }
            });

            if (defaultSection.widgets.length) {
                defaultSection.widgetRows = Math.ceil(defaultSection.widgets.length / this.cols);
                this.sections.push(defaultSection)
            }

        } else {
            this.sections = [
                {
                    widgets: this.widgets,
                    widgetRows: Math.ceil(this.widgets.length / this.cols)
                }
            ]
        }

        this.sections.forEach(s => {
            if (this.childType === 'orgboard') {
                s.widgets.sort((w1, w2) => {

                    if (w1.data.country.indexOf('INT') === 0 && w2.data.country.indexOf('INT') < 0) {
                        return -1;
                    } else if (w2.data.country.indexOf('INT') === 0 && w1.data.country.indexOf('INT') < 0) {
                        return 1;
                    } else if (w1.data.country.indexOf('INT') === 0 && w2.data.country.indexOf('INT') === 0) {
                        return w1.data.country.indexOf('INT-F') === 0 ? -1 : 1;
                    }

                    return w1.data && w2.data && this.translateService.instant('country.' + w1.data.country) > this.translateService.instant('country.' + w2.data.country) ? 1 : -1
                })
            }
        })
    }

    addWidget(widget) {
        this.widgets.unshift(widget);
        this.widgets = this.widgets.sort((w1: IWidget, w2: IWidget) => {
            return (w1.sort || '').localeCompare(w2.sort || '');
        }).reverse()
        this.buildSections();
    }

    editAllowed(widget: IWidget) {
        if (this.editMode && this.updateAllowed && this.data.allowCreate) {
            if (this.childType === 'post') {
                return this.isManualPosts ||
                    widget.data.parentId == this.id && widget.data.parentType?.toLocaleLowerCase() == 'widget';
            } else {
                // this widgets are always allowed to be edited;
                return true;
            }
        }

        return false;
    }

    createEntity() {
        if (['post', 'letter', 'room'].indexOf(this.childType) > -1) {

            const isManualPosts = this.isManualPosts;

            this.structureService.createPost(
                this.childType,
                isManualPosts ? this.parentData.postsOf : 'widget',
                isManualPosts ? this.parentId : this.id,
                (post) => {
                    this.widgets.unshift(['letter'].indexOf(this.childType) > -1 ? post : this.preparePostForPresentation(post));
                },
                {
                    gridId: this.id,
                    gridData: this.data
                }
            )
        } else {
            this.structureService.addCollectionWidget(this, this.childType);
        }

        this.gqlService.setCurrentFetchPolicy('network-only');
    }

    bulkCreateEntities() {
        if (this.allowsBulkCreation()) {
            this.structureService.bulkAddCollectionWidget(this, this.childType);
        }

        this.gqlService.setCurrentFetchPolicy('network-only');
    }

    editEntity(entity: IWidget | IPost, index: number) {
        if (['post', 'letter', 'room'].indexOf(this.childType) > -1) {
            const isLetter = this.childType === 'letter';
            this.structureService.editPost(this.childType, entity as IPost, (post) => {
                this.widgets[index] = isLetter ? post : this.preparePostForPresentation(post);
            }, {
                gridId: this.id,
                gridData: this.data
            })
        } else {
            this.structureService.editCollectionWidget(this, entity as IWidget, (updatedWidget) => {
                if (updatedWidget && updatedWidget.data._onUpdate) {
                    updatedWidget.data._onUpdate.next(updatedWidget.data);
                }
            });
        }

        this.gqlService.setCurrentFetchPolicy('network-only');
    }

    removeEntity(entity: IWidget, index: number) {
        const callback = () => {
            this.widgets.splice(index, 1); // remove the element
            this.gqlService.setCurrentFetchPolicy('network-only');
        };

        if (['post', 'letter', 'room'].indexOf(this.childType) > -1) {
            const isManualPosts = this.isManualPosts;
            this.structureService.removePost(isManualPosts || this.childType === 'letter' ? entity : entity.data, callback);
        } else {
            this.structureService.removeCollectionWidget(entity, callback);
        }
    }


    getLoop(iterations: number) {
        return new Array(iterations + 1);
    }

    getCurrentYear() {
        return (new Date()).getFullYear();
    }

    allowsBulkCreation() {
        return this.updateAllowed && this.data.allowCreate && ['image', 'inspiration'].indexOf(this.childType) > -1;
    }

    async filterChange(field, event) {

        if (this.filters[field] !== event.target.value) {
            // the filter changed
            this.filters[field] = event.target.value;

            if (!event.target.value) {
                delete this.filters[field];
            }

            this.reset();

            this.isFiltered = Object.keys(this.filters).length > 0;
            this.filtering = true;

            await this.loadNextWidgetGrid();

            this.filtering = false;
        }
    }

    async clearFilters() {
        this.filters = {};

        this.reset()

        this.filtering = true;

        // @todo - this is hardcoded and should be removed
        this.yearInput.nativeElement.value = '';
        this.typeInput.nativeElement.value = '';

        await this.loadNextWidgetGrid();

        this.isFiltered = false;
        this.filtering = false;
    }

    async exportData() {
        this.exporting = true;

        if (!this.allLoaded) {
            const oldLimit = this.limit;
            this.limit = 500; // load em all

            while (!this.allLoaded) {
                await this.loadNextWidgetGrid();
            }

            this.limit = oldLimit
        }

        // alright, we have all the data, so let's export it
        const memberIds = [];
        const memberAccess = {};

        const addMemberIfExists = (memberId) => {
            if (memberId && memberIds.indexOf(memberId) < 0) {
                memberIds.push(memberId);
            }
        }

        this.widgets.forEach((widget) => {
            widget.data.directorsMembersIds?.forEach(addMemberIfExists);
            widget.data.managementMembersIds?.forEach(addMemberIfExists);
            widget.data.supervisoryMembersIds?.forEach(addMemberIfExists);
        })

        const members = await this.memberService.getMembers(memberIds);

        members.forEach((member: IUser) => {
            memberAccess[member.id] = member;
        });

        const headers = {
            country: 'Country / Organisation',
            name: 'Name',
            email: 'Email',
            center: 'Center',
            position: 'Board',
            official: 'Official Org Name',
            link: 'Profile Link'
        }

        const rows = [headers];

        const addRow = (org, userId, position) => {

            // some members might be dissabled or hidden
            if (memberAccess[userId]) {

                const user = memberAccess[userId];

                rows.push({
                    country: this.translateService.instant('country.' + org.country),
                    name: `${user.givenName} ${user.familyName}`,
                    email: user.email,
                    center: user.center?.displayName || '',
                    position,
                    official: org.organizationName,
                    link: window.location.origin + this.routingHelper.getMemberRoute(user)
                })
            }
        }

        this.widgets.forEach((widget) => {
            widget.data.directorsMembersIds?.forEach(userId => {
                addRow(widget.data, userId, 'Board of Directors')
            });
            widget.data.supervisoryMembersIds?.forEach(userId => {
                addRow(widget.data, userId, 'Supervisory Board')
            });
            widget.data.managementMembersIds?.forEach(userId => {
                addRow(widget.data, userId, 'Management Board')
            });
        })

        const data = rows.map((row) => {
            return Object.values(row).join(',');
        }).join('\n')
        const blob = new Blob([data], {type: 'text/csv'});

        // Creating an object for downloading url
        const url = window.URL.createObjectURL(blob)

        // Creating an anchor(a) tag of HTML
        const a = document.createElement('a')

        // Passing the blob downloading url
        a.setAttribute('href', url)

        // Setting the anchor tag attribute for downloading
        // and passing the download file name
        a.setAttribute('download', `export_${(new Date()).getFullYear()}-${((new Date()).getMonth() + 1).toFixed(0).padStart(2, '0')}-${(new Date()).getDate().toFixed(0).padStart(2, '0')}.csv`);
        a.click();
        this.exporting = false;
    }

  nextSlide() {
    this.slides.nativeElement.swiper.slideNext();
  }

  prevSlide() {
    this.slides.nativeElement.swiper.slidePrev();
  }

  updateSlideUi(index){

    const visibleSlides = Math.floor(this.slides.nativeElement.swiper["size"] / this.slides.nativeElement.swiper["slidesSizesGrid"][0]);
    this.disablePrevBtn = index === 0;
    this.disableNextBtn = index + visibleSlides === this.widgets.length;

    if (index + visibleSlides > this.widgets.length - 10) {
      this.loadNextWidgetGrid();
    }

  }
}
