import { IWidgetDefinition } from '../../core/model/widget.model';

export default ({
    icon: {style: "fa-regular", name: 'circle-dot'},
    fields: [
        {
            id: 'title',
            label: 'Title',
            input: 'text',
            required: true,
        }
    ]
} as IWidgetDefinition)
