import { SsoEnvironment } from '../app/core/auth/environment.service';

const appSettings = {
    version: '1.22.3'
};


export interface AppEnv extends SsoEnvironment {
  backendBasePath: string;
  eventApiBasePath: string;
  awarenessApiBasePath: string;
  streamingBasePath: string;
  catalogApiBasePath: string;
  cloudBasePath: string;
  registrationBasePath: string;
  ssoNativeRedirectUri: string;
  ssoWebRedirectUri: string;
  ssoPath: string;
  proxyBasePath: string;
  typesenseUri: string,
  typesenseCollection: string,

  /**
   * The DW-Connect internal vacants rooms in center project id
   */
  vacantRoomsId: number

  /**
   * enables or disables accommodation requests on the centre page
   */
  features: {
    accommodationRequest: boolean,
    showStyleguide: boolean,
    eventWebcomponentDebug: boolean,
  }
}

export const version = appSettings.version;

export const ssoAppId = '337a17f119364bbab169ab1cfb72d192';

const devFeatures = {
  accommodationRequest: true,
  showStyleguide : true,
  eventWebcomponentDebug: true,
}

const prodFeatures = {
  accommodationRequest: true,
  showStyleguide: false,
  eventWebcomponentDebug: false
}


export const ENVs: Map<string, AppEnv> = new Map([
  ['production_pwa', {
    backendBasePath: 'https://backend.dw-connect.org',
    eventApiBasePath: 'https://eventapi.dwbn.org',
    awarenessApiBasePath: 'https://awareness.dwbn.org',
    streamingBasePath: 'https://streaming.dwbn.org',
    registrationBasePath: 'https://registration.dwbn.org',
    catalogApiBasePath: 'https://catalog.dwbn.org',
    cloudBasePath: 'https://cloud.dwbn.org',
    ssoPath: 'https://sso.dwbn.org',
    ssoNativeClientId: 'not in pwa', // live app id
    ssoJsClientId: 'af1e84800de842a49363fccea66bce9c', // live app id
    ssoNativeRedirectUri: 'org.dwbn.dwconnect:/oauth2redirect',
    ssoWebRedirectUri: 'https://dw-connect.org/implicit/authcallback',
    endSessionRedirectUrl: 'org.dwbn.dwconnect:/endSession',
    proxyBasePath: 'https://assets.dwbn.org/scale/intranet/',
    typesenseUri: 'https://typesense.hoth.dwbn.org',
    typesenseCollection: 'dw_connect_org_content',
    vacantRoomsId: 276,
    features: prodFeatures
  } as AppEnv],
  ['local_live', {
    backendBasePath: 'https://backend.dw-connect.org',
    eventApiBasePath: 'https://eventapi.dwbn.org',
    awarenessApiBasePath: 'https://awareness.dwbn.org',
    streamingBasePath: 'https://streaming.dwbn.org',
    registrationBasePath: 'https://registration.dwbn.org',
    catalogApiBasePath: 'https://catalog.dwbn.org',
    cloudBasePath: 'https://cloud.dwbn.org',
    ssoPath: 'https://sso.dwbn.org',
    ssoNativeClientId: 'TODO', // live app id
    ssoJsClientId: '421376b8262143f2bafd7cde3aba518b', // dev + live app id
    ssoNativeRedirectUri: 'org.dwbn.dwconnect:/oauth2redirect',
    ssoWebRedirectUri: 'http://localhost:8100/implicit/authcallback',
    endSessionRedirectUrl: 'org.dwbn.dwconnect:/endSession',
    proxyBasePath: 'https://assets.dwbn.org/scale/intranet/',
    typesenseUri: 'https://typesense.hoth.dwbn.org',
    typesenseCollection: 'dw_connect_org_content',
    vacantRoomsId: 276,
    features: prodFeatures
  } as AppEnv],
  ['stage_pwa', {
    backendBasePath: 'https://backend-stage.dw-connect.org',
    eventApiBasePath: 'https://eventapi.dwbn.org',
    awarenessApiBasePath: 'https://awareness.dwbn.org',
    streamingBasePath: 'https://streaming.dwbn.org',
    registrationBasePath: 'https://registration.dwbn.org',
    catalogApiBasePath: 'https://catalog.dwbn.org',
    cloudBasePath: 'https://cloud.dwbn.org',
    ssoPath: 'https://sso.dwbn.org',
    ssoNativeClientId: 'not in pwa', // live app id
    ssoJsClientId: 'af1e84800de842a49363fccea66bce9c', // live app id
    ssoNativeRedirectUri: 'org.dwbn.dwconnect:/oauth2redirect',
    ssoWebRedirectUri: 'https://app-stage.dw-connect.org/implicit/authcallback',
    endSessionRedirectUrl: 'org.dwbn.dwconnect:/endSession',
    proxyBasePath: 'https://assets.dwbn.org/scale/intranet/',
    typesenseUri: 'https://typesense.ferrix.dwbn.org',
    typesenseCollection: 'dw_connect_org_content',
    vacantRoomsId: 276,
    features: devFeatures
  } as AppEnv],
  ['dev', {
    backendBasePath: 'https://backend-dev.dw-connect.org',
    eventApiBasePath: 'https://eventapi.ferrix.dwbn.org',
    awarenessApiBasePath: 'https://awareness.ferrix.dwbn.org',
    streamingBasePath: 'https://streaming.ferrix.dwbn.org',
    registrationBasePath: 'https://registration.ferrix.dwbn.org',
    catalogApiBasePath: 'https://catalog-dev.dwbn.org',
    cloudBasePath: 'https://cloud-dev.dwbn.org',
    ssoPath: 'https://sso-dev.dwbn.org',
    ssoNativeClientId: 'a9042adc3c6d45d2b1f65f140a250fa1', // dev native app id
    ssoJsClientId: '421376b8262143f2bafd7cde3aba518b', // dev pwa id
    ssoNativeRedirectUri: 'org.dwbn.dwconnect:/oauth2redirect',
    ssoWebRedirectUri: 'https://app-dev.dw-connect.org/implicit/authcallback',
    endSessionRedirectUrl: '',
    proxyBasePath: 'https://assets.dwbn.org/scale/intranet/',
    typesenseUri: 'https://typesense.ferrix.dwbn.org',
    typesenseCollection: 'app_dev_dw_connect_org_content',
    vacantRoomsId: 121,
    features: devFeatures
  } as AppEnv],
  ['local_dev', {
    // local backend:
    // backendBasePath: 'http://127.0.0.1:8000', // has some corese issues
    // or for easier dev:
    backendBasePath: 'https://backend-dev.dw-connect.org',
    eventApiBasePath: 'https://eventapi.ferrix.dwbn.org',
    awarenessApiBasePath: 'https://awareness.ferrix.dwbn.org',
    streamingBasePath: 'https://streaming.ferrix.dwbn.org',
    registrationBasePath: 'https://registration.ferrix.dwbn.org',
    catalogApiBasePath: 'https://catalog-dev.dwbn.org',
    cloudBasePath: 'https://cloud-dev.dwbn.org',
    ssoPath: 'https://sso-dev.dwbn.org',
    ssoNativeClientId: 'a9042adc3c6d45d2b1f65f140a250fa1', // dev native app id
    ssoJsClientId: '421376b8262143f2bafd7cde3aba518b', // dev pwa id
    ssoNativeRedirectUri: 'org.dwbn.dwconnect:/',
    ssoWebRedirectUri: 'http://dw-connect.localhost:8100',
    endSessionRedirectUrl: 'org.dwbn.dwconnect:/endSession',
    proxyBasePath: 'https://assets.dwbn.org/scale/intranet/',
    typesenseUri: 'https://typesense.ferrix.dwbn.org',
    typesenseCollection: 'app_dev_dw_connect_org_content',
    vacantRoomsId: 121,
    features: devFeatures
  } as AppEnv],
  ['k8s_dev', {
//    backendBasePath: 'https://dwc2backend.dwc2-backend-local.svc.cluster.local',
    backendBasePath: 'https://backend-dev.dw-connect.org',
    eventApiBasePath: 'https://eventapi.ferrix.dwbn.org',
    awarenessApiBasePath: 'https://awareness.ferrix.dwbn.org',
    streamingBasePath: 'https://streaming.ferrix.dwbn.org',
    registrationBasePath: 'https://registration.ferrix.dwbn.org',
    catalogApiBasePath: 'https://catalog-dev.dwbn.org',
    cloudBasePath: 'https://cloud-dev.dwbn.org',
    ssoPath: 'https://sso-dev.dwbn.org',
    ssoNativeClientId: 'a9042adc3c6d45d2b1f65f140a250fa1', // dev native app id
    ssoJsClientId: '421376b8262143f2bafd7cde3aba518b', // dev pwa id
    ssoNativeRedirectUri: 'org.dwbn.dwconnect:/',
    ssoWebRedirectUri: 'https://app-local.dw-connect.org/implicit/authcallback',
    endSessionRedirectUrl: 'org.dwbn.dwconnect:/endSession',
    proxyBasePath: 'https://assets.dwbn.org/scale/intranet/',
    typesenseUri: 'https://typesense.ferrix.dwbn.org',
    typesenseCollection: 'app_dev_dw_connect_org_content',
    vacantRoomsId: 121,
    features: devFeatures
  } as AppEnv]
]);

