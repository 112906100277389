import {Injectable} from '@angular/core';
import gql from 'graphql-tag';
import {Apollo} from 'apollo-angular';
import {IPost} from '../model/post.model';
import {MessageService} from './message.service';
import {ProjectService} from './project.service';
import {WidgetService} from './cms/widget.service';
import {CenterService} from './center.service';
import {IFollowable} from '../model/followable.model';
import {GqlService} from './GQL/gql.service';
import {PageService} from './cms/page.service';
import {TitledAppImageFragment} from './GQL/gql-query.service';
import {firstValueFrom} from "rxjs";
import {cloneDeep} from 'lodash-es';
import {DataHelper} from "../util/data.helper";

const PostFragment = gql`
fragment PostFragment on Post {
  id
  title
  type
  content
  data
  images {
    ...TitledAppImageFragment
  }
  created
  lastModified
  parentId
  parentType
  categories {
    id
  }
}
${TitledAppImageFragment}
`;

const PostFragmentWithoutCategories = gql`
fragment PostFragmentWithoutCategories on Post {
  id
  title
  type
  content
  data
  images {
    ...TitledAppImageFragment
  }
  created
  lastModified
  parentId
  parentType
}
${TitledAppImageFragment}
`;

const GET_LATEST_FOLLOWED_POSTS_QUERY = gql`
query my_latest_posts($followablesToFilter: [FollowableInput], $limit: Int, $offset: Int) {
   my_latest_posts(followablesToFilter: $followablesToFilter, limit: $limit, offset: $offset) {
      ...PostFragmentWithoutCategories
      views
      lastViewed
   }
}
${PostFragmentWithoutCategories}
`;

const GET_POSTS_QUERY = gql`
query getPosts ($filter: String, $offset: Int, $limit: Int) {
  post_list (filter: $filter, offset: $offset, limit: $limit) {
    count
    posts {
      ...PostFragment
    }
  }
}
${PostFragment}
`;

const GET_POST_CREATE_INFO_QUERY = gql`
query getPost ($id: Int) {
  post (id: $id) {
    id
    created
    createdBy {
      id
      username
      givenName
      familyName
    }
    lastModified
    lastModifiedBy {
      id
      username
      givenName
      familyName
    }
  }
}`

const GET_POST_QUERY = gql`
query getPost ($id: Int) {
  post (id: $id) {
    ...PostFragment
    views
  }
}
${PostFragment}
`;

const ADD_POST_MUTATION = gql`
mutation addPost($data: AddPostInput!) {
  addPost (input: $data) {
    ...PostFragment
  }
}
${PostFragment}
`;

const TEST_MAIL_MUTATION = gql`
mutation testMail($data: AddPostInput!) {
  testMail (input: $data)
}
`;

const EDIT_POST_MUTATION = gql`
mutation editPost($data: UpdatePostInput!) {
  updatePost (input: $data) {
    ...PostFragment
  }
}
${PostFragment}
`;

const POST_VIEW_MUTATION = gql`
mutation viewPost($postId: Int!) {
  viewPost(postId: $postId)
}

`;

const REMOVE_MUTATION = gql`
mutation deletePost($id: Int!) {
  deletePost(postId: $id)
}
`;


export interface INewsPostsCriteria {
    followables?: IFollowable[],
    limit?: number,
    offset?: number
}

@Injectable({
    providedIn: 'root'
})

export class PostsService {

    constructor(
        private apollo: Apollo,
        private messageService: MessageService,
        private projectService: ProjectService,
        private widgetService: WidgetService,
        private centerService: CenterService,
        private pageService: PageService,
        private gqlService: GqlService,
        private dataHelper: DataHelper
    ) {
    }

    private static cleanImages(images: any[]) {
        return images ? images.map((image) => {
            delete image.__typename;
            delete image.image?.__typename;
            delete image.image?.filters;

            return image;
        }) : []
    }

    private prepareCleanPost(post: IPost) {
        const data = {
            parentType: post.parentType,
            parentId: post.parentId.toString(),
            title: JSON.stringify(post.title),
            content: JSON.stringify(post.content),
            images: PostsService.cleanImages(post.images),
            categories: post.categories?.map((catOrNumber) => {
                if (typeof catOrNumber === 'number') {
                    return catOrNumber
                }

                return catOrNumber.id;
            }),
            data: undefined,
            type: undefined
        };

        if (post.data) {
            data.data = JSON.stringify(this.dataHelper.assureFieldDefinitionData(post.type, post.data));
        }

        if (post.type) {
            data.type = post.type;
        }

        return data;
    }


    async getPosts(parentType: string, parentId: string) {
        const result: any = await firstValueFrom(this.apollo
            .use('app')
            .query({
                query: GET_POSTS_QUERY,
                variables: {filter: JSON.stringify({andX: [{eq: {parentType}}, {eq: {parentId}}]}), limit: 10}
            }));

        return result ? (result.data.post_list.posts as IPost[]) : null;
    }

    async getNewsPosts(criteria: INewsPostsCriteria = {followables: [], limit: 10, offset: 0}): Promise<IPost[]> {
        const result: any = await firstValueFrom(this.apollo
            .use('app')
            .query({
                query: GET_LATEST_FOLLOWED_POSTS_QUERY,
                variables: {followablesToFilter: criteria.followables, limit: criteria.limit, offset: criteria.offset}
            }));

        if (result) {
            const posts: IPost[] = cloneDeep(result.data.my_latest_posts);
            const postsWithParents: IPost[] = await Promise.all(
                posts.map(async (post) => {
                    post.parent = await this.fetchParent(post.parentType, post.parentId);
                    return post
                })
            );
            return postsWithParents as IPost[];
        } else {
            return [];
        }
    }

    async fetchParent(parentType: string, parentId: string): Promise<any> {
        // would be nice to define a return type here that tells us what to expect from a normalized parent
        switch (parentType) {
            case 'Project':
                return await this.projectService.getProject(parseInt(parentId, 10));
            case 'Widget':
                return await this.widgetService.getWidget(parseInt(parentId, 10));
            case 'Center':
                return await this.centerService.getCenterById(parentId);
            case 'Page':
                return await this.pageService.getPageById(parseInt(parentId, 10));
        }
    }

    async getWidgetsByFilter(filter, offset, limit): Promise<IPost[]> {
        const fetchPolicy = await this.gqlService.getCurrentFetchPolicy();
        // this is used exclusively on the WidgetGrid page
        // renamed from 'getPostsByFilter' because it's not canonical for posts
        // eg, we don't use it on the News Feed
        const result: any = await firstValueFrom(this.apollo
            .use('app')
            .query({
                query: GET_POSTS_QUERY,
                variables: {filter: JSON.stringify(filter), limit, offset},
                fetchPolicy
            }));

        return result ? (cloneDeep(result.data.post_list.posts) as IPost[]) : null;
    }

    /**
     * marking a post as viewed
     */
    async postView(postId: number): Promise<number | null> {

        const result: any = await firstValueFrom(this.apollo
            .use('app')
            .mutate({
                mutation: POST_VIEW_MUTATION,
                variables: {postId}
            })).catch(() => {
            }); // catch when the post doesn't exist but do nothing. we show the 404 downstream.


        return result.data?.viewPost;
    }

    async getPost(postId: number) {
        const result: any = await firstValueFrom(this.apollo
            .use('app')
            .query({
                query: GET_POST_QUERY,
                variables: {id: postId}
            })).catch(() => {
            }); // catch when the post doesn't exist but do nothing. we show the 404 downstream.

        if (result) {
            const post = cloneDeep(result.data.post)
            post.parent = await this.fetchParent(post.parentType, post.parentId);
            return post as IPost;
        } else {
            return null;
        }
    }

    async getPostCreateInformation(postId: number) {
        const result: any = await firstValueFrom(this.apollo
            .use('app')
            .query({
                query: GET_POST_CREATE_INFO_QUERY,
                variables: {id: postId}
            })).catch(() => {
            }); // catch when the post doesn't exist but do nothing. we show the 404 downstream.


        const post = result.data.post
        return post as IPost;
    }

    async testMail(post: IPost) {
        const data = this.prepareCleanPost(post);


        const result: any = await firstValueFrom(this.apollo.use('app').mutate({
            mutation: TEST_MAIL_MUTATION,
            variables: {data}
        })).catch((reason) => {
            this.messageService.error(this.messageService.apolloErrorToMessage(reason));
        });

        return result?.data?.testMail;
    }

    async addPost(post: IPost) {

        const data = this.prepareCleanPost(post);

        const result: any = await firstValueFrom(this.apollo.use('app').mutate({
            mutation: ADD_POST_MUTATION,
            variables: {data}
        })).catch((reason) => {
            this.messageService.error(this.messageService.apolloErrorToMessage(reason));
        });

        return result?.data?.addPost
    }

    async editPost(post: IPost): Promise<IPost> {

      const result: any = await firstValueFrom(this.apollo.use('app').mutate({
            mutation: EDIT_POST_MUTATION,
            variables: {
                data: {
                    postId: post.id,
                    title: JSON.stringify(post.title),
                    content: JSON.stringify(post.content),
                    images: PostsService.cleanImages(post.images || []),
                    data: post.data ? JSON.stringify(this.dataHelper.assureFieldDefinitionData(post.type, post.data)) : null,
                    categories: post.categories?.map((catOrNumber) => {
                        if (typeof catOrNumber === 'number') {
                            return catOrNumber
                        }

                        return catOrNumber.id;
                    }),
                }
            }
        })).catch((reason) => {
            this.messageService.error(this.messageService.apolloErrorToMessage(reason));
        });

        return result?.data?.updatePost
    }

    async removePost(post: IPost): Promise<boolean> {
        const result: any = await firstValueFrom(this.apollo.use('app').mutate({
            mutation: REMOVE_MUTATION,
            variables: {id: post.id}
        })).catch((reason) => {
            this.messageService.error(this.messageService.apolloErrorToMessage(reason));
        });

        return (result.data.deletePost as boolean);
    }
}
