import {PostComponent} from "./post.component";
import {IWidgetDefinition} from "../../core/model/widget.model";

const ifIsVideo = (fieldValues) => {
    return fieldValues.includeVideo === true;
};

const ifIsNotVideo = (fieldValues) => {
    return fieldValues.includeVideo !== true;
};

const ifIsExternal = (fieldValues) => {
    return ifIsVideo(fieldValues) && fieldValues.videoType === 'external';
};

const ifIsJwPlayer = (fieldValues) => {
    return ifIsVideo(fieldValues) && fieldValues.videoType === 'dwbn_jwplayer';
};

export default ({
  fields: [
    {
        id: 'title',
        input: 'text',
        required: true,
        i18n: true
    },
    {
        id: 'content',
        input: 'html',
        i18n: true
    },
    {
        id: 'categories',
        label: false,
        input: 'category-select',
        custom: {
            label: 'Categories',
            description: 'The available categories can be configured on the parent collection.'
        }
    },
    {
        id: 'includeVideo',
        input: 'boolean',
        label: 'Use a video instead of Images'
    },
    {
        id: 'images',
        depends: ifIsNotVideo,
        input: 'complex-list',
        label: 'Images',
        subtype: [
            {
                id: 'image',
                input: 'image_upload',
                multiple: false,
                required: true
            },
            {
                id: 'title',
                label: 'Description',
                input: 'text',
            }
        ]
    },

    {
        id: 'videoType',
        depends: ifIsVideo,
        required: true,
        input: 'select',
        label: 'Video Source Type',
        default: 'external',
        options: [
            {label: 'External (like YouTube or Videmo)', value: 'external'},
            {label: 'DWBN JW Player', value: 'dwbn_jwplayer'},
        ],
        description: 'Please check <a href="/projects/diamond-way-connect/documentation/video-widget" target="_blank">this documentation</a> for an explenation on videos in dw-connect.'
    },

    {
        id: 'videoSrc',
        depends: ifIsExternal,
        label: 'Youtube or Vimeo video share link',
        input: 'text',
        description: 'Please use the full link starting with <strong>https://</strong>.',
        required: true,
    },
    {
        id: 'videoM3u8',
        depends: ifIsJwPlayer,
        label: 'The actual m3u8 or cloud.dwbn.org link used by JwPlayer',
        input: 'text',
        description: 'Please use the full link starting with <strong>https://</strong>.',
        required: true,
    },
    {
        id: 'videoCover',
        depends: ifIsJwPlayer,
        label: 'Cover Picture',
        input: 'image_upload',
        description: 'You can use a custom video thumbail here, it will be modified to show a video icon on top.',
        required: false,
    },
    {
        id: 'videoAspectRatio',
        depends: ifIsVideo,
        label: 'Aspect Ration of the Video (Height divided by Width)',
        input: 'number',
        required: true,
        default: 0.56,
        description: 'A standard video has 0.56, a youtube short has 1.78 for example.'
    }

]
} as IWidgetDefinition );
