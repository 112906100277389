import {IWidgetDefinition} from '../../core/model/widget.model';

export default ({
    icon: {style: "fa-solid", name: 'list'},
    category: 'content',
    fields: [
        {
            id: 'title',
            input: 'text',
            required: true,
            i18n: true
        },
        {
            id: 'center',
            input: 'text',
            label: 'Center Slug',
            required: true,
            description: 'The slug is the last part of the the center url.'
        }
    ]
} as IWidgetDefinition);
