import {Pipe, PipeTransform} from "@angular/core";
import {IColSizes} from "../model/colSizes.model";
import {getColSizeClasses} from "../util/template.helper";

@Pipe({
  name: 'colSizeClasses',
  pure: false
})
export class ColSizeClassesPipe implements PipeTransform {

  transform(colSizes: IColSizes): string {
    return getColSizeClasses(colSizes);
  }
}
