import {IWidgetDefinition} from '../../core/model/widget.model';

export default ({
    icon: {style: 'fa-regular', name: 'calendar-days'},
    category: 'content',
    fields: [
        {
            id: 'headline',
            input: 'text'
        },
        {
            id: 'tag',
            input: 'text',
            label: 'Tag'
        },
        {
            id: 'center',
            input: 'text',
            label: 'Center Slug',
            description: 'The slug is the last part of the the center url. Multiple centers can be put comma seperated'
        },
        {
            id: 'region',
            input: 'text',
            label: 'Region(s)',
            description: 'You can put multiple regions seperated with a comma'
        },
        {
            id: 'country',
            input: 'text',
            label: 'ISO-2 Country Code(s)',
            description: 'You can put multiple countries seperated with a comma'
        }
    ]
} as IWidgetDefinition);
