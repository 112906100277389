import {Injectable } from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {EnvironmentService } from '../services/environment.service';
import {from, Observable} from 'rxjs';
import {catchError, switchMap} from 'rxjs/operators';
import {Oauth2Service} from "./oauth2.service";

@Injectable({
  providedIn: 'root'
})
export class TokenInterceptor implements HttpInterceptor {

  private interceptables: string[];

  constructor(
      private env: EnvironmentService,
      private oauth2Helper: Oauth2Service
    ) {
      this.interceptables = [
          this.env.currentEnv.backendBasePath + '/api',
          this.env.currentEnv.awarenessApiBasePath + '/api',
          this.env.currentEnv.backendBasePath + '/graph',
          this.env.currentEnv.eventApiBasePath,
        ];
  }

  shallIntercept(req: HttpRequest<any>) {
    return this.interceptables.some( urlPart => req.url.indexOf(urlPart) === 0 );
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.shallIntercept(req)) {
      return next.handle(req);
    }
    // console.log('handling ', req)
    return from(this.oauth2Helper.getValidToken()).pipe(
      switchMap((accessToken) => {
        return accessToken ?
          next.handle(TokenInterceptor.addToken(req, accessToken)) :
          next.handle(TokenInterceptor.addRefreshToken(req, this.oauth2Helper.refreshToken))
      }),

      catchError((error) => {
        throw new Error(error.message);
      }));
  }

  static addToken(req: HttpRequest<any>, token: string|null): HttpRequest<any> {
    if (!token) {
      throw new Error('No valid access token!');
    }
    return req.clone({setHeaders: {Authorization: 'Bearer ' + token}});
  }

  static addRefreshToken(req: HttpRequest<any>, refreshToken: string|null): HttpRequest<any> {
    if (!refreshToken) {
      throw new Error('No refreshToken token given!');
    }
    return req.clone({setHeaders: {'X-REFRESH-TOKEN': refreshToken}});
  }

  handle401Error(req: HttpRequest<{accessToken: string}>, next: HttpHandler, error: Error) {
    console.log('401, ', req, error);
    this.oauth2Helper.signIn();
  }

  private handleError(req: HttpRequest<any>, next: HttpHandler, error: HttpErrorResponse) {
    console.log('http handling error, ', req, error);
    throw new Error(error.message);
  }
}
