import {Component, OnDestroy, OnInit} from '@angular/core';
import {DWCWidgetComponent} from "../../core/model/dwc-widget.model";
import {StructureService} from "../../core/services/cms/structure.service";
import {TypesenseService} from "../../core/services/typesense.service";
import {ITypeSenseContentDocument} from "../../core/model/searchResult.model";
import {TranslationHelper} from "../../core/util/translation.helper";
import {IAppImage} from "../../core/model/app-image.model";
import {IColSizes} from "../../core/model/colSizes.model";
import {getSubColSizes} from "../../core/util/template.helper";
import {AccessService, PERMISSION_POST, PERMISSION_UPDATE} from "../../core/services/access.service";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-child-pages',
  templateUrl: './child-pages.component.html'
})
export class ChildPagesComponent implements OnInit, OnDestroy, DWCWidgetComponent {

  id: number;
  type: string;
  data: any;
  colSizes: object;

  cols = 3;
  displaySizes: IColSizes;

  results: ITypeSenseContentDocument[] = [];

  $editMode: Subscription;
  $editable: Subscription;

  editMode = false;
  editable = false;
  updateAllowed = false;

  constructor(
    private structureService: StructureService,
    private typesenseService: TypesenseService,
    public translationHelper: TranslationHelper,
    private accessService: AccessService,
  ) {

    this.$editMode = this.structureService.editModeObservable.subscribe(async (editMode) => {
      this.editMode = editMode;
      this.updateAllowed = await this.structureService.rightCurrentlyGranted(PERMISSION_UPDATE);
    });
    this.$editable = this.accessService.editableObservable.subscribe(editable => this.editable = editable);

  }

  ngOnInit(): void {
    const pageId = this.structureService.getCurrentPageId();

    if (this.data.cols) {
      this.cols = parseInt(this.data.cols, 10);
    }

    const colSizes = {xs: 12, sm: 12, md: 6, lg: (12 / this.cols)};

    this.displaySizes = getSubColSizes({}, colSizes);

    this.typesenseService.rawSearch({
      q: '"page-' + pageId + '"',
      query_by: 'structural_parent',
      filter_by: 'type:=page',
      sort_by: this.data.sortBy || 'title:desc',
      per_page: this.data.limit == '0' ? '250' : this.data.limit
    }).then((res) => {
      this.results = res.hits.map((hit) => {
        return hit.document
      });
    });
  }

  ngOnDestroy(): void {
    this.$editable.unsubscribe();
    this.$editMode.unsubscribe();
  }

  createPage() {
    this.structureService.createPage(this.structureService.getCurrentPage().getRoute());
  }
}
