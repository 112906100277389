import {Injectable} from '@angular/core';
import {LectureService, RecurringService} from 'src/app/api/eventapi/api/api';
import {map} from 'rxjs/operators';
import {Observable, from, firstValueFrom} from 'rxjs';
import {LectureEventsRead} from 'src/app/api/eventapi/model/models';


const EVENT_API_PARAMS = {
    audiences: undefined,
    eventStatus: undefined,
    'centers.id': undefined,
    'centers.slug': undefined,
    'relevantForRegions.id': undefined,
    'relevantForRegions.slug': undefined,
    'relevantForCountries.id': undefined,
    'relevantForCountries.code': undefined,
    international: undefined,
    'organizedInRegions.id': undefined,
    'organizedInRegions.slug': undefined,
    'organizedInCountries.id': undefined,
    'organizedInCountries.code': undefined,
    public: undefined,
    'startDate[before]': undefined,
    'startDate[strictly_before]': undefined,
    'startDate[after]': undefined,
    'startDate[strictly_after]': undefined,
    'endDate[before]': undefined,
    'endDate[strictly_before]': undefined,
    'endDate[after]': undefined,
    'endDate[strictly_after]': undefined,
    'order[startDate]': undefined,
    latitude: undefined,
    longitude: undefined,
    excludeOwnCenter: undefined,
    radius: undefined,
    topicId: undefined,
    technicalTag: undefined,
    page: undefined,
    itemsPerPage: undefined
};

@Injectable({
    providedIn: 'root'
})
export class EventService {

    constructor(
        private lectureApiService: LectureService,
        private recurringEventsApiService: RecurringService,
    ) {
    }

    buildSimpleParams(data: { tag?: string, additionalEventParameters?: string }) {
        let eventParams: any = {};
        if (data.additionalEventParameters) {
            try {
                eventParams = JSON.parse(data.additionalEventParameters);
            } catch (e) {
                console.error('Exception while parsing JSON string %o: %o', data.additionalEventParameters, e);
            }
        }

        if (data.tag) {
            eventParams.tag = data.tag;
        }

        return eventParams;
    }

    getCenterEvents(slug: string) {
        const params = {
            ...EVENT_API_PARAMS,
            'centers.slug': slug
        };
        return this.getEventsBase(params);
    }

    async getRecurringCenterEvents(slug: string) {
        const params = {
            ...EVENT_API_PARAMS,
            'centers.slug': slug
        };

        const res = await this.recurringEventsApiService.getRecurringCollection(params).toPromise();

        return res['hydra:member'] || [];
    }

    getNationalEvents(countryCode: string): Observable<LectureEventsRead[]> {
        if (countryCode) {
            const params: any = {
                ...EVENT_API_PARAMS,
                'relevantForCountries.code': countryCode,
                itemsPerPage: 108
            };

            return this.getEventsBase(params);
        }

        return from([]);
    }

    getInternationalEvents(): Observable<Array<LectureEventsRead>> {
        const params = {
            ...EVENT_API_PARAMS,
            technicalTag: 'international'
        };

        return this.getEventsBase(params);
    }

    getEventsNearLocation(latitude: number, longitude: number, radiusInKm: number = 150): Observable<Array<LectureEventsRead>> {
        const params = {
            ...EVENT_API_PARAMS,
            latitude,
            longitude,
            radius: radiusInKm,
            itemsPerPage: 108
        };

        return this.getEventsBase(params);
    }

    async getOneTimeEvents(simpleParams): Promise<LectureEventsRead[]> {

        const params: any = {
            ...EVENT_API_PARAMS,
            itemsPerPage: 108
        };

        if (simpleParams.country) {
            // params.relevantForCountriesCode = simpleParams.country; - this is a AND filter currently, so we removing it now till we get the original requirement back
            params['organizedInCountries.code'] = simpleParams.country;
            delete simpleParams.country;
        }
        if (simpleParams.tag) {
            params.technicalTag = simpleParams.tag;
            delete simpleParams.tag;
        }
        if (simpleParams.center) {
            params['centers.slug'] = simpleParams.center;
            delete simpleParams.center;
        }
        if (simpleParams.region) {
            params['organizedInRegions.slug'] = simpleParams.region;
            delete simpleParams.region;
        }

        Object.keys(simpleParams).forEach((nativeApiParam) => {
            params[nativeApiParam] = simpleParams[nativeApiParam];
        });

        return await firstValueFrom(this.lectureApiService.getLectureCollection(params, 'body').pipe(
            map(res => res['hydra:member'] || [])
        ));
    }

    async getLecture(eventSourceInfo: { id?: string, sourceId?: string, sourceType?: string }) {
        return await firstValueFrom(eventSourceInfo.id ?
          this.lectureApiService.getLectureItem(eventSourceInfo.id) :
          this.lectureApiService.getLectureSourceItem(eventSourceInfo.sourceId, eventSourceInfo.sourceType)
        );
    }

    async getInternationalLectures() {
        return await firstValueFrom(this.lectureApiService.getInternationalLectures().pipe(
            map(res => res['hydra:member'] || [])
        )) as LectureEventsRead[];
    }

  async getStreamingSessions() {
    return firstValueFrom(this.getEventsBase({
      technicalTag: 'StreamingSession',
      itemsPerPage: 20
    }));
  }

    private getEventsBase(params: any): Observable<Array<LectureEventsRead>> {
        return this.lectureApiService.getLectureCollection(params, 'body').pipe(map(res => res['hydra:member'] || []));
    }


    public processEvent(event, formatTimes = false) {
        if (!event) return
        const formatLength = '2020-11-20'.length;
        const correctFormat = event.startDate.slice(0, formatLength);
        const dateArray = correctFormat.split('-');
        const eventDay = dateArray[2];
        const eventMonth = dateArray[1];
        const eventYear = dateArray[0];

        if (formatTimes) {
            event.endDay = event.endDate && new Date(event.endDate).toLocaleDateString('de-DE', {day: 'numeric'})
            event.endMonth = event.endDate && new Date(event.endDate).toLocaleDateString('de-DE', {month: 'numeric'})
            event.startTimeString = event.startDate && new Date(event.startDate).toLocaleTimeString('de-DE', {
                hour: 'numeric',
                minute: 'numeric'
            })
            event.endTimeString = event.endDate &&
                new Date(event.endDate).toLocaleTimeString('de-DE', {hour: 'numeric', minute: 'numeric'})
        }

        let host
        if (event.url) {
            host = event.url.replace('https://', '').replace('http://', '')
            if (host[host.length - 1] === '/') {
                host = host.substr(0, host.length - 1)
            }
        }

        event.statusName = event.eventStatus.replace('http://schema.org/', '')

        return {...event, eventDay, eventMonth, eventYear, host}
    }
}
