import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {IAppIcon} from "../../core/model/app-icon.model";
import {makeAnchorId} from "../../core/util/string.helper";

@Component({
  selector: 'app-section-header',
  templateUrl: './section-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SectionHeaderComponent implements OnInit {

  @Input() title: string;
  @Input() icon: IAppIcon = null;

  idSlug: string;

  constructor() {}

  ngOnInit() {
    this.idSlug = makeAnchorId(this.title);
  }

}
