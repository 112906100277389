import { Component, OnInit } from '@angular/core';
import {ProjectService} from '../../core/services/project.service';
import {UserService} from '../../core/services/user.service';
import {combineLatest} from 'rxjs';
import {RoutingHelper} from '../../core/util/routing.helper';
import {IColSizes} from "../../core/model/colSizes.model";
import {getSubColSizes} from 'src/app/core/util/template.helper';

@Component({
  selector: 'app-projects-list',
  templateUrl: './projects-list.component.html',
})
export class ProjectsListComponent implements OnInit {

  constructor(
      private projectService: ProjectService,
      public userProvider: UserService,
      public routingHelper: RoutingHelper
  ) { }

  myProjects: any[];
  myFollowedProjects: any[];
  otherProjects: any[];

  projectInvitations: any[] = [];

  colSizes: IColSizes = {xs: 12};
  subColSizes: IColSizes = {xs: 12, sm: 6, md: 4, xl: 4};
  colSizeClasses: IColSizes;

  ngOnInit() {

    this.colSizeClasses = getSubColSizes(this.colSizes, this.subColSizes);

    combineLatest([
        this.projectService.projectList(),
        this.userProvider.projectInvitationObservable
    ]).subscribe(([result, projectInvitations]) => {
      if (result) {
        this.myProjects = result.my_projects;
        this.myFollowedProjects = result.my_followed_projects;
        this.otherProjects = result.other_projects;
      }

      if (projectInvitations) {
        this.projectInvitations = projectInvitations;
      }
    })
  }

}
