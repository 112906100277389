import { Component, OnInit } from '@angular/core';
import {DWCWidgetComponent} from '../../core/model/dwc-widget.model';
import {BrowserService} from '../../core/services/browser.service';
import {TranslationHelper} from "../../core/util/translation.helper";

@Component({
  selector: 'app-image',
  templateUrl: './image.component.html',
  // styleUrls: ['./image.component.scss'],
})
export class ImageComponent implements OnInit, DWCWidgetComponent {

  data: any;
  id: number;
  type: string;
  display: string;

  colSizes: object; // information how wide the wrapper column of the component is

  aspectRatio: number

  title: string;
  description: string;

  constructor(
      private browserService: BrowserService,
      public translationHelper: TranslationHelper
  ) { }

  ngOnInit() {

    this.title = this.translationHelper.getBestLanguageValue(this.data?.title);
    this.description = this.translationHelper.getBestLanguageValue(this.data?.description);

    if (this.data?.manualAspectRatio) {
      this.aspectRatio = parseFloat(this.data?.manualAspectRatio);
    } else if (this.display !== 'preview') {
      this.aspectRatio = this.data?.image?.aspectRatio as number;
    } else {
      this.aspectRatio = 0.6;
    }
  }

  navigate () {
    if (this.data.linkToPage) {
      this.browserService.navigateLink(this.data?.linkToPage);
    }
  }

}
