import {IWidgetDefinition} from '../../core/model/widget.model';
import iconSelect from '../../core/util/forms/icons.select';

export default ({
    icon: {style: "fa-regular", name: 'file'},
    fields: [
        iconSelect,
        {
            id: 'headline',
            input: 'text',
            i18n: true,
            width: 8
        },
        {
            id: 'files',
            input: 'complex-list',
            required: false,
            subtype: [
                {
                    id: 'file',
                    input: 'file_upload',
                    label: 'File',
                    required: false
                },
                {
                    id: 'filename',
                    input: 'text',
                    required: false
                }
            ]
        }
    ]
} as IWidgetDefinition);
