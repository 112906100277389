import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {makeAnchorId, makeSlug} from "../../core/util/string.helper";

@Component({
  selector: 'app-icon-box',
  templateUrl: './icon-box.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconBoxComponent implements OnInit {

  @Input() icon: string;
  @Input() headline: string;
  @Input() noBlock = false;
  @Input() align: 'left' | 'stack' = 'left';

  idSlug: string

  constructor() { }

  ngOnInit() {
    this.idSlug = makeAnchorId(this.headline);
  }

}
