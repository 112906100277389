<section class="projects-list">
    <div *ngIf="projectInvitations.length">

      <app-section-header title="{{'projects.titles.invitations' | translate }}" [icon]="{style: 'dw', name: 'alert'}"/>

      <div class="row">
          <div *ngFor="let invitation of projectInvitations" class="col-12 col-md-6 col-xl-4">
              <a [href]="routingHelper.getProjectRoute(invitation.project)" [routerLink]="routingHelper.getProjectRoute(invitation.project)" *ngIf="invitation?.project?.picture?.src">
                  <app-img [roundedCorners]="true" align="left" width="50%" [colSizes]="{xs: 12, md: 6, lg: 6, xl: 4}" [aspectRatio]="0.6" [image]="invitation?.project?.picture"></app-img>
              </a>
              <div>
                  <h4 class="headline">{{invitation.project.name}}</h4>
                  <button class="btn btn-primary" type="button" (click)="userProvider.acceptInvitationToProject(invitation.project.id)">{{'general.accept' | translate}}</button>
                  <button class="btn btn-outline-dark ms-2" (click)="userProvider.ignoreInvitationToProject(invitation.project.id)">{{'general.ignore' | translate}}</button>
              </div>
          </div>
      </div>
    </div>

    <ng-container *ngTemplateOutlet="list;context:{icon: 'friends', headline: ('projects.titles.yourProjects' | translate), projects: myProjects}"></ng-container>
    <ng-container *ngTemplateOutlet="list;context:{icon: 'inspiration', headline: ('projects.titles.yourFollowedProjects' | translate), projects: myFollowedProjects}"></ng-container>
    <ng-container *ngTemplateOutlet="list;context:{icon: 'project', headline: ('projects.titles.otherProjects' | translate), projects: otherProjects}"></ng-container>
</section>

<ng-template #list let-icon="icon" let-headline="headline" let-projects="projects">
    <div *ngIf="projects && projects.length" class="projects-list__category">

      <app-section-header title="{{headline}}" [icon]="{style: 'dw', name: icon}"/>

        <div class="row">
            <div *ngFor="let project of projects" class="{{colSizeClasses | colSizeClasses}} mb-4">
              <app-project-tile [project]="project" [colSizes]="subColSizes"></app-project-tile>
            </div>
        </div>
    </div>

</ng-template>
