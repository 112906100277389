import {IWidgetDefinition} from '../../core/model/widget.model';
import currencySelect from '../../core/util/forms/currency.select';

export default ({
    icon: {style: 'fa-solid', name: 'chart-pie'},
    category: 'payment',
    fields: [
        {
            id: 'headline',
            required: true,
            input: 'text',
            default: 'Contribute to the Project'
        },
        currencySelect,
        {
            id: 'donation_target',
            input: 'number',
            description: 'Allows to set a donation target',
            width: 6,
        },
        {
            id: 'donation_current',
            input: 'number',
            description: 'The current donated amount',
            width: 6,
        },
        {
            id: 'link_to_page',
            input: 'boolean',
        },
        {
            id: 'link',
            input: 'route',
            description: 'Full relative link to a DW-Connect page.',
            required: true,
            width: 6,
            depends: (formValues) => {
                return formValues.link_to_page;
            }
        },
        {
            id: 'buttonLabel',
            i18n: true,
            input: 'text',
            label: 'Button label',
            placeholder: 'widgets.donation.support',
            width: 6,
            depends: (formValues) => {
                return formValues.link_to_page;
            }
        },
    ]
} as IWidgetDefinition)
